import React,  {useState, useEffect, useCallback, useRef, createRef}  from "react";
import { Row, Col, Container, Button, Form, InputGroup, Card, Modal } from 'react-bootstrap';
import { Search, X, XCircle, ChatLeftText, BoxArrowUpRight, PencilSquare, Pencil, ArrowLeftRight, Check, Trash, Save, Stop, SlashCircle } from 'react-bootstrap-icons';
import {Tab} from "bootstrap";
import '../styles/cases.scss';
import Moment from 'react-moment';
import moment from 'moment';
import DOMPurify from 'dompurify';
import {Link, useNavigate} from 'react-router-dom';
import { useOutletContext } from "react-router-dom";
import useWebSocket, { ReadyState } from 'react-use-websocket';
import { DEFAULT_OPTIONS } from "react-use-websocket/dist/lib/constants";
import { useAuth } from "../helpers/AuthContext";
import CaseServices from "../services/CaseServices";
import { v4 as uuidv4 } from 'uuid';
import ResearchForm from "../components/ResearchForm";
import ExportChat from "../components/ExportChat";
import ConfirmDeleteSession from "../components/ConfirmDeleteSession";
import ConfirmEditQuestion from "../components/ConfirmEditQuestion";
import Image from '../components/Image';
import globals from "../components/globals";
import { responsivePropType } from "react-bootstrap/esm/createUtilityClasses";
import Indicator from "../components/indicator";

const Chat = () => {
  const url = new URL(process.env.REACT_APP_APP_API_ENDPOINT);
  const leftLogo = process.env.REACT_APP_LEFT_LOGO;
  const rightLogo = process.env.REACT_APP_RIGHT_LOGO;
  const greetPrompt = process.env.REACT_APP_CHAT_GREET;
  const botLetters = process.env.REACT_APP_CHAT_BOT_LETTERS ?? "A";
  const chatMessage = process.env.REACT_APP_CHAT_MESSAGE ?? "My name is Aida, your helpful assistant for information on the Caribbean justice system. How may I assist you today?";
  const newChatTitle = process.env.REACT_APP_CHAT_TITLE ?? "Welcome to AIDA, your AI Powered Research Assistant";
  const leftImage = Image({ fileName:leftLogo, alt: 'GCT Logo', className: 'left-logo' })
  const rightImage = Image({ fileName:rightLogo, alt: 'EC Logo', className: 'right-logo' })
  const WS_URL = `wss://${url.host}/ws`;
  const chatTitleRef = useRef(null);
  const [pageClass, setPageClass] = useOutletContext();
  const [loaded, setLoaded] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [editTitle, setEditTitle] = useState(false);
  const [showEditor, setShowEditor] = useState(false);
  const [showSide, setShowSide] = useState(false);
  const [chatTitle, setChatTitle] = useState('');
  const [sidechat, setSideChat] = useState(null);
  const [summaryData, setSummaryData] = useState(null);
  const { user, token } = useAuth();
  const [search, setSearch] = useState('');
  const [socketUrl, setSocketUrl] = useState(WS_URL);
  const [allowSelect, setAllowSelect] = useState(false);
  const [exportActive, setExportActive] = useState(false);
  const [summarizeList, setSummarizeList] = useState([]);
  const [chatList, setChatList] = useState([]);
  const [removesession, setRemoveSession] = useState(false);
  const [editquestion, setEditQuestion] = useState(false);
  const [elRefs, setElRefs] = React.useState([]);
  const [refreshList, setRefreshList] = useState(false);
  const [selectedChat, setSelectedChat] = useState(null);
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [showExport, setShowExport] = useState(false);
  const options = { ...DEFAULT_OPTIONS, protocols: [`AuthorizationToken`, btoa(token)] }
  const { sendMessage, lastMessage, readyState } = useWebSocket(socketUrl,
    {
      //...DEFAULT_OPTIONS,
      protocols: ["Authorization", token],
      shouldReconnect: (closeEvent) => true,
      reconnectAttempts: 99999,
      //attemptNumber will be 0 the first time it attempts to reconnect, so this equation results in a reconnect pattern of 1 second, 2 seconds, 4 seconds, 8 seconds, and then caps at 10 seconds until the maximum number of attempts is reached
      reconnectInterval: (attemptNumber) =>
      Math.min(Math.pow(2, attemptNumber) * 1000, 10000),
    });

  useEffect(() => {
    if (!loaded) {
      setPageClass('cases');
      setLoaded(true);
      //Call Data Loaded API HERE
      CaseServices.getChats().then((chats) => {
        console.log(chats);

        for (var i = 0; i < chats.data.length; i++) {
            chats.data[i].MessageIdentifier = uuidv4();
            chats.data[i].active = false;
            chats.data[i].history = [];
            chats.data[i].waiting = false;
            chats.data[i].loaded = false;
            chats.data[i].message = "";
        }
        setChatList(chats.data);
        setDataLoaded(true);
      });
    }
  },[loaded]); 

  useEffect(() => {dataLoaded && newChat() }, [dataLoaded]);

  useEffect(() => {
    if (lastMessage !== null && lastMessage !== undefined) {
      var msg = JSON.parse(lastMessage.data);
      console.log(msg);
      msg.chatMessage = bold(msg.chatMessage);
      msg.chatMessage = order(msg.chatMessage);
      msg.chatMessage = newline(msg.chatMessage);
      var chats = [...chatList];
      for (var i = 0; i < chatList.length; i++) {
        if (chats[i].MessageIdentifier ==  msg.messageIdentifier) {
            chats[i].chatId = msg.chatId;
            var idx = chats[i].history.findIndex(c => c.chatMessageId == msg.chatMessageId);
            if (idx == -1) {
              var old = {...chats[i].history[chats[i].history.length - 2]};
              //chats[i].history[chats[i].history.length - 2] = {...chats[i].history[chats[i].history.length - 2], chatMessageId : msg.requestorId, chatId : msg.chatId};
              old.chatMessageId = msg.requestorId;
              old.chatId = msg.chatId;
              chats[i].history[chats[i].history.length - 1] = msg;
              chats[i].history[chats[i].history.length - 2] = old;
            } else {
              chats[i].history[idx].chatMessage = msg.chatMessage;
            }
            //setElRefs((elRefs) => Array(chats[i].history.length).fill().map((_, p) => elRefs[p] || createRef()),);
            chats[i].history = chats[i].history.map((c) => ({...c, REF : c.REF || createRef()}));
            chats[i].waiting = false;
            chats[i].history = [...chats[i].history];
        }
      }
      // add or remove refs
      setChatList(chats);

      scrollToBottom();
    }
  }, [lastMessage]);

  const bold = (text) => {
    var bold = /\*\*(.*?)\*\*/gm;
    var html = text.replace(bold, '<strong>$1</strong>');            
    return html;
  }
  
  const order = (text) => {
    var regex = /\n(^\d+)\.(.*)\n/gm;
    var matches = text.matchAll(regex);
    var entries = [];
    for (const match of matches) 
        entries.push(match);
    if (entries.length == 0)
        return text;
    const last = entries[entries.length - 1];
    return text.slice(0, entries[0].index) + '<ol>' +  text.slice(entries[0].index,last.index + last[0].length).replace(regex, `<li index='$1'>$2</li>`) + '</ol>' + text.slice(last.index + last[0].length, text.length);
  }

  const newline = (text) => {
    var line = /(.*?)\n/gm;
    var html = text.replace(line, '$1<br/>');
    var html = html.replace("</ol><br/>", "</ol>");
    return html;
  }

  const reference = (text) => {
    console.log(text);
    var line = /【(\d+)\†source】/g;
    var pdfs = /-\ ([A-Za-z0-9_ \-\%\$\&\*]*\.pdf)/g;
    var pdfEntries = [];
    var html = '';
    for (const match of text.matchAll(pdfs)) {
        pdfEntries.push(match);        
    }
    var lineEntries = [];
    for (const match of text.matchAll(line)) {
        lineEntries.push(match);
    }

    var start = 0;
    for (const match of pdfEntries) {
      html += text.substr(0, match.index);
      console.log(match);
      html += `<a class="docref" href="#docs/${match[1]}">${text.substr(match.index, match[0].length)}</a>`;
      console.log(html);
      start = match.index + match[0].length;
    }
    html += text.substr(start, text.length - start);

    return html;
  }
  
  const handleClickSendSummarizeMessage = (session) => {
    
  }

  const handleClickSendChatMessage = (session) => {
    console.log(session);
    session.waiting = true;
    var chats = [...chatList];
    var send = true;
    for (var i = 0; i < chats.length; i ++) {
        if (chats[i].MessageIdentifier == session.MessageIdentifier) {
            if (chats[i].chatId == 0) {
              const index = i;
              //chats[i].title = session.message;
              CaseServices.summarizeTitle(session.message).then((response) => {
                var chats = [...chatList];
                chats[index].title = response.data;
                setChatTitle(response.data);
                sendMessage(JSON.stringify({
                  MessageIdentifier : session.MessageIdentifier,
                  ChatId : session.chatId,
                  UserId : user.id,
                  Title: chats[index].title,
                  ChatMessage : session.message
                }));            
                session.message = "";
                setChatList(chats);
              });
              send = false;
            }
            var msgId = new Date().getUTCMilliseconds();
            chats[i].history = [...chatList[i].history, {
                "chatMessageId": msgId,
                "chatId": session.chatId,
                "userId": user.id,
                "chatMessage": session.message,
                "isResponse": false,
                "REF": createRef(),
            },{
              "chatMessageId": msgId,
              "chatId": 0,
              "userId": user.id,
              "chatMessage": '',
              "isResponse": true,
          }];
        }
    }

    if (send) {
      sendMessage(JSON.stringify({
        MessageIdentifier : session.MessageIdentifier,
        ChatId : session.chatId,
        UserId : user.id,
        ChatMessage : session.message
      }));
      session.message = "";
      setChatList(chats);
    }
  }

  const activateChat = (index) => {
    let list = [...chatList];

    if (sidechat && list[index].chatId == sidechat.chatId)
      return;
  

    for (var i = 0; i < list.length; i++) {
        list[i].active = i == index;
    }
    var active = list[index];
    if (!active.loaded) {
        CaseServices.getChatHistory(active.chatId).then((response) => {
            console.log(response);
            if (response.data.chatMessages.length > 0) {
                for (var i = 0; i < response.data.chatMessages.length; i++) {
                    response.data.chatMessages[i].chatMessage = formatChatGPTResponse(response.data.chatMessages[i].chatMessage);
                    response.data.chatMessages[i].REF = createRef();
                }
                active.history = response.data.chatMessages;
            }
            else
                active.history = [{
                    "chatMessageId": new Date().getUTCMilliseconds(),
                    "chatId": active.chatId,
                    "userId": user.id,
                    "chatMessage": chatMessage,
                    "isResponse": true,
                }];
            active.loaded = true;
            setChatTitle(list[index].title);
            setChatList(list);
        });
    }
    setChatTitle(list[index].title);
    setChatList(list);
  }

  const formatChatGPTResponse = (message) => {
    message = bold(message);
    message = order(message);
    message = newline(message);
    message = reference(message);
    return message;
  }

  const connectionStatus = {
    [ReadyState.CONNECTING]: 'Connecting',
    [ReadyState.OPEN]: 'Connected',
    [ReadyState.CLOSING]: 'Disconnecting',
    [ReadyState.CLOSED]: 'Disconnected',
    [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
  }[readyState];

  const newChat = () => {
    for (var i = 0; i < chatList.length; i++)
        chatList[i].active = false;

    let list = [...chatList, {
        history : [{
            "chatMessageId": new Date().getUTCMilliseconds(),
            "chatId": 0,
            "userId": user.id,
            "chatMessage": chatMessage,
            "isResponse": true,
        }],
        active: true,
        loaded: true,
        chatId: 0,
        title: "",
        MessageIdentifier: uuidv4(),
        message: "",
    }];
    setChatList(list);
  }

  const cancelSearch = () => {
    setSearch('');
  }

  const messagesEndRef = useRef(null);
  const scrollToBottom = () => {
    setTimeout(() => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    }, 100)
  }

  const setChatMessage = (message, chat) => {
    var chats = [...chatList];
    chat.message = message;
    setChatList(chats);
  }

  const exportChat = () => {
    setExportActive(!exportActive);
  }

  const setSelectedSummaryItem = (idx) => {
    summarizeList[idx] = !summarizeList[idx];
    setSummarizeList([...summarizeList]);
  }

  const summarizeChat = (chat) => {
    setAllowSelect(!allowSelect);
    setSummarizeList(Array.apply(null, Array(chat.history.length)).map(function () { return false; }))
  }

  const summarizeMessages = (chat) => {
    CaseServices.summarizeChatSession(chat.title, chat.chatId, chat.history.filter((x, idx) => summarizeList[idx] ? x.chatMessageId : null).map(x => x.chatMessageId)).then((response) => {
      console.log(response);
      setSummaryData(formatChatGPTResponse(response.data));
      setShowEditor(true);
      cancelSummarize(chat);
    });
  }

  const setTitle = (chat) => {
    setEditTitle(false);
    chat.title = chatTitle;
    setChatTitle(chat.title);
    CaseServices.saveTitle(chat.chatId, chat.title);
    console.log("1");
  }

  const cancelTitleEdit = (chat) => {
    setEditTitle(false);
    setChatTitle(chat.title);
    console.log("2");
  }

  const removeSession = (chat) => {
    setSelectedChat(chat);
    setRemoveSession(true);
  }

  const editQuestion = (message) => {
    console.log(message);
    message.Editing = true;
    //setSelectedMessage(message);
    var chats = [...chatList];
    setChatList(chats);
    message.REF.current.focus();
    message.oldChatMessage = message.chatMessage;
    //setEditQuestion(true);
  }

  const updateMessage = (event, message) => {
    if (!event)
      return;
    console.log(event, message);
    message.chatMessage = event.currentTarget.textContent;
  }

  const saveQuestion = (message, chat) => {
    console.log(message);
    message.Editing = false;
    var chats = [...chatList];
    chat.waiting = true;
    setChatList(chats);
    sendMessage(JSON.stringify({
      MessageIdentifier : chat.MessageIdentifier,
      ChatId : message.chatId,
      ChatEntryId: message.chatMessageId,
      UserId : user.id,
      ChatMessage : message.chatMessage
    }));
    var idx = chat.history.findIndex(c => c.chatMessageId == message.chatMessageId);
    if (idx > -1) {
      chat.history[idx+1].chatMessage = '';
    }
    //session.message = "";
    setChatList(chats);

    // CaseServices.updateMessage(message.chatId, {
    //   chatId: message.chatId,
    //   chatMessageId: message.chatMessageId,
    //   chatMessage: message.chatMessage,
    //   messageIdentifier: "UPDATE",
    // });
  }
  const cancelEditQuestion = (message, chat) => {
    console.log("cancel");
    message.Editing = false;
    chat.waiting = false;
    message.chatMessage = message.oldChatMessage;
    message.REF.current.innerText = message.oldChatMessage;
    var chats = [...chatList];
    setChatList(chats);
  }

  const cancelSummarize = (chat) => {
    setAllowSelect(false)
    setSummarizeList([]);
  }

  const disableSummarize = (chat) => {
    if (!chat || !chat.history)
      return true;
    if (chat.history.length <= 0)
      return true;

    if (chat.history.length == 1 && chat.history[0].chatMessage == chatMessage)
      return true;
    return false;
  }

  const sideBySide = (chat) => {
    if (!chat.loaded) {
      CaseServices.getChatHistory(chat.chatId).then((response) => {
        console.log(response);
        if (response.data.chatMessages.length > 0) {
            for (var i = 0; i < response.data.chatMessages.length; i++) {
                response.data.chatMessages[i].chatMessage = bold(response.data.chatMessages[i].chatMessage);
                response.data.chatMessages[i].chatMessage = order(response.data.chatMessages[i].chatMessage);
                response.data.chatMessages[i].chatMessage = newline(response.data.chatMessages[i].chatMessage);
                response.data.chatMessages[i].chatMessage = reference(response.data.chatMessages[i].chatMessage);
            }
            chat.history = response.data.chatMessages;
        }
        else
          chat.history = [{
                "chatMessageId": new Date().getUTCMilliseconds(),
                "chatId": chat.chatId,
                "userId": user.id,
                "chatMessage": chatMessage,
                "isResponse": true,
            }];
        chat.loaded = true;
        setSideChat(chat);
        setShowSide(true);
      });
    }
    else {
      setSideChat(chat);
      setShowSide(true);
    }
  }

  const closeSide = (chat) => {
    console.log(chat);

    setSideChat(null);
    setShowSide(false);
  }

  const closeSummary = () => {
    setShowEditor(false);
  }

  useEffect(() => {
    if (editTitle)
      chatTitleRef.current.focus();
  }, [editTitle]);

  const handleKeyDown = (event) => {
    console.log(event);
  }

  useEffect(() => {
    if (refreshList) {
      var list = chatList.filter(x => x.chatId != selectedChat.chatId);
      setSelectedChat(null);
      setSelectedMessage(null);
      setChatList(list);
      setRefreshList(false);
    }
  }, [refreshList])

  //useEffect(scrollToBottom, [messageHistory]);

  return (<>
    <ExportChat chat={chatList.filter(chat => chat.active)[0]} show={exportActive} setShow={setExportActive} />
    <ConfirmDeleteSession chat={selectedChat} show={removesession} setShow={setRemoveSession} setRefresh={setRefreshList} />
    {/* <ConfirmEditQuestion message={selectedMessage} show={editquestion} setShow={setEditQuestion} setRefresh={setRefreshList} /> */}
    <Container className='layout-height'>
      <Row>
        <Col md={12} className="chatBody m-0 p-0">
          <div className="wrapper">
            <div className="container">
              <div className="left">
                <div className="top">
                  <div className="logo-area">{leftImage}</div>
                  <div className="searchgroup">
                    <Search className="searchicon" />
                    <Form.Control type="text" placeholder="Search all sessions" onChange={(e) => setSearch(e.target.value)} value={search} />
                    {/* <X className="closeicon" onClick={cancelSearch} disabled={search == ''} /> */}
                  </div>
                </div>
                <div className="middle">
                  <div className="researchtitle"><ChatLeftText></ChatLeftText><span>Sessions</span></div>
                  <div className="researchsessions">
                    <ul className="people">
                      {chatList.filter(chat => chat.title.toLowerCase().includes(search.toLowerCase()) && chat.chatId != 0).map((chat, idx) => (
                      <li key={"li_chat" + chat.chatId} className={"person" + (chat.active ? " active" : "")} data-chat={"person" + chat.chatId}>
                        <span className="name" onClick={() => activateChat(idx)}>{chat.title}</span>
                        {/* <span className="time">{moment(chat.createdDate).fromNow()}</span> */}
                        {!chat.active && !(sidechat && sidechat.chatId == chat.chatId) ? <span className="compare" title="View Side By Side" onClick={() => sideBySide(chat)}><ArrowLeftRight /></span> : <></>}
                        {!chat.active && !(sidechat && sidechat.chatId == chat.chatId) ? <span className="delete" title="Remove Research Session" onClick={() => removeSession(chat)}><Trash /></span> : <></>}
                      </li>
                      ))}
                    </ul>
                  </div>
                </div>
                <div className="bottom">
                  <Button className="search" title="New Session" onClick={newChat}>&nbsp;&nbsp;&nbsp;New Session</Button>
                </div>
                <div className="toolbar">
                  <Button title="Export Session" className="export" onClick={() => exportChat()}><BoxArrowUpRight /></Button>
                  <Button title="Summarize Chat" disabled={disableSummarize(chatList.filter(chat => chat.active)[0])} className={"summarize" + (allowSelect ? " active" : "")} onClick={() => summarizeChat(chatList.filter(chat => chat.active)[0])}><PencilSquare /></Button>
                </div>
              </div>
              <div className="right">
                <div className="logo-area">{rightImage}</div>
                  <div className="top">
                    <div className="content">
                      <div className="mid" style={{padding:"0 35px",margin:"30px 0 0 50px"}}>
                        {chatList.filter(chat => chat.active).map((chat, idx) => (//should always only return 1
                        <div key={"active_chat_"+chat.chatId}>
                        {(editTitle == true ? 
                        <span className="title">
                          <InputGroup>
                            <Form.Control ref={chatTitleRef} type="text" placeholder="Set the title for this session" onFocus={(e) => {} } onChange={(e) => setChatTitle(e.target.value)} value={chatTitle} />
                            <InputGroup.Text>
                              <Check className="save" onClick={() => setTitle(chat)} />
                              <X className="close" onClick={() => cancelTitleEdit(chat)} />
                            </InputGroup.Text>
                          </InputGroup>
                          {/* <Form.Control
                            ref={chatTitleRef}
                            type="text" placeholder="Set the title for this session" onChange={(e) => setChatTitle(e.target.value)} value={chatTitle} onBlur={() => setTitle(chat)}
                          />
                          <X className="close" onClick={() => cancelTitleEdit(chat)} /> */}
                        </span>
                        :
                        <span className="title">{chat.title != "" ? chat.title : newChatTitle}{chat.chatId ? <Pencil className="edit" onClick={() => setEditTitle(true)} /> : <></>}</span>
                        )}
                        <span className="created">{moment(chat.createdDate).toLocaleString()}</span>
                        <span className="last">Last activity {chat.history.length > 0 ? moment(chat.history[chat.history.length - 1].createdDate).fromNow() : "never" }</span>
                        </div>))}
                      </div>
                    </div>
                    {showSide ? <>
                    <div className="content sidechat">
                      <div className="mid" style={{padding:"0 35px",margin:"30px 0 0 50px"}}>
                        <span className="title">{sidechat.title}</span>
                        <span className="created">{moment(sidechat.createdDate).toLocaleString()}</span>
                        <span className="last">Last activity {sidechat.history.length > 0 ? moment(sidechat.history[sidechat.history.length - 1].createdDate).fromNow() : "never" }</span>
                      </div>
                    </div>
                    </>: <></>}
                  </div>
                  <div className={!allowSelect ? "bottom" : "bottom sidechat"}>
                    <div className={!allowSelect ? "content" : "content tooltip-active-element"}>
                    {chatList.map((chat, idx) => (<>
                      <div key={"messages_" + chat.chatId} className={"chat" + (chat.active ? " active-chat" : "")} data-chat={"person" + chat.chatId}>
                        {/* {chat.waiting ? <div className="waiting"><div className="dot-floating"></div></div>: <></>} */}
                        {!allowSelect ? <></> : chat.active ?
                        <div className="summarizer">
                          <Button className="cancel" title="Cancel" onClick={() => cancelSummarize()}>Cancel</Button>
                          <Button className="submit" title="Generate" disabled={summarizeList.filter(x => x == true).length <= 0} onClick={() => summarizeMessages(chatList.filter(chat => chat.active)[0])}>Summarize</Button>
                          <Button className="selectall" title="Select All" onClick={() => setSummarizeList(new Array(summarizeList.length).fill(!(summarizeList.filter(x => x == true).length == summarizeList.length)))}>
                            {summarizeList.filter(x => x == true).length == summarizeList.length ? <>Unselect All</> : <>Select All</>}
                          </Button>
                        </div> : <></>}
                        <div className="scrollarea">
                          {chat.history.map((msg, idx) => (
                          <div key={"msgchat_" + chat.chatId + "_index_" + idx}>
                            {!msg.isResponse ? <div className="mecircle">{user.firstName[0]+user.lastName[0]}</div> : <></>}
                            <div className={"bubble " + (msg.isResponse ? (msg.chatMessage != '' ? 'you' : 'you wait') : 'me')}>
                              {msg.isResponse && msg.chatMessage == '' ? <Indicator className={chat.waiting ? "indicator" : "indicator none"} /> : <></>}
                              <blockquote ref={msg.REF} contentEditable={msg.Editing && !msg.isResponse ? "true" : "false"} value={msg.chatMessage} dangerouslySetInnerHTML={{__html: msg.chatMessage}} onInput={e => updateMessage(e, msg)}></blockquote>
                              {msg.chatMessage != '' && chat.history.length > 1 && !msg.isResponse ? 
                                !msg.Editing ?
                                <span className="edit" title="Edit Question" onClick={() => editQuestion(msg)}>
                                  <Pencil />
                                </span>
                                :
                                <>
                                <span className="update" title="Update Question" onClick={() => saveQuestion(msg, chat)}>
                                  <Save />
                                </span>
                                <span className="cancel" title="Cancel" onClick={() => cancelEditQuestion(msg, chat)}>
                                  <XCircle />
                                </span>
                                </>
                                : 
                                <></>}
                            </div>
                            {msg.isResponse ?
                            <div className={ !allowSelect ? "youcircle" : !summarizeList[idx] ? "youcircle selectable" : "youcircle selected" } onClick={() => allowSelect && setSelectedSummaryItem(idx)}>
                              {summarizeList[idx] ? 
                              <><Check /></> : 
                              <>{botLetters}</>}
                            </div>
                            : <></>}
                          </div>))}
                          <div ref={messagesEndRef}>&nbsp;</div>
                        </div>
                        <div className="hider">
                          <div className="write">
                            <InputGroup>
                              <Form.Control type="text" disabled={chat.waiting} placeholder={greetPrompt} onKeyDown={(event) => event.which == 13 && !(readyState !== ReadyState.OPEN || chat.message == '') && handleClickSendChatMessage(chat)} onChange={(e) => setChatMessage(e.target.value, chat)} value={chat.message} />
                              <InputGroup.Text>
                                <Button className="write-link send" onClick={() => handleClickSendChatMessage(chat)} disabled={readyState !== ReadyState.OPEN || chat.message == '' || chat.waiting}></Button>
                                {/* {chat.waiting ? <Indicator className="indicator" />: <></>} */}
                              </InputGroup.Text>
                            </InputGroup>
                          </div>
                        </div>
                      </div></>))}
                    </div>
                      {allowSelect ? <div className="backdrop"></div> : <></>}
                      {showEditor ? <>
                      <div className="summary">
                        <div className="editor">
                          <div className="close-summary" onClick={closeSummary}><XCircle /></div>
                          <div className="scrollable">
                            <ResearchForm data={summaryData} />
                          </div>
                        </div>
                      </div>
                      </>: <></>}
                      {showSide ? <>
                      <div className="content sidechat">
                        <div key={"sidemessages_" + sidechat.chatId} className="chat active-chat" data-chat={"person" + sidechat.chatId}>
                          <div className="closeSide" onClick={() => closeSide(sidechat)}><XCircle /></div>
                          {sidechat.waiting ? <div className="waiting"><div className="dot-floating"></div></div>: <></>}
                          <div className="scrollarea">
                            {sidechat.history.map((msg, idx) => (
                            <div key={"sidechat_" + sidechat.chatId + "_history_" + msg.chatMessageId}>
                              {!msg.isResponse ? <div className="mecircle">{user.firstName[0]+user.lastName[0]}</div> : <></>}
                              <div className={"bubble " + (msg.isResponse ? 'you' : 'me')} dangerouslySetInnerHTML={{__html: msg.chatMessage}}></div>
                              {msg.isResponse ? <div className="youcircle">{botLetters}</div> : <></>}
                            </div>
                            ))}
                            <div ref={messagesEndRef}>&nbsp;</div>
                          </div>
                        </div>
                      </div>
                    </>: <></>}
                  </div>
                </div>
            </div>
          </div>
        </Col>
      </Row>
      {/* <Row><Col><span>AIDA Status - {connectionStatus}</span></Col><Col style={{textAlign:'right'}}><small>Powered by AdventAI&copy;</small></Col></Row> */}
    </Container>
  </>);
};

export default Chat;
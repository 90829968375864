import { Container } from "react-bootstrap";
import "../styles/sidebar.scss";
import History from "./History";
import { useEffect, useRef, useState } from "react";

const SideBar = ({ChatHistory, SetChatHistory, SetSelectedChat, SideBarActive, SetSideBarActive}) => {

  const newChat = () => {
    for (var i = 0; i < ChatHistory.length; i++)
      ChatHistory[i].active = false;

    console.log("new chat");
    SetSelectedChat({active: true, loaded: true, history : []});
    SetChatHistory([...ChatHistory]);
  }

  const [scroll, setScroll] = useState(0);
  const onScroll = (e) => {
    setScroll(e.target.scrollTop);
    //console.log(e.target.scrollTop);
  };  

  return <div className="sidebar">
    <button className="newsession" onClick={newChat}>
      <span className="big">Start new session</span>
      <span className="small"></span>
    </button>
    <div className="bar"></div>
    <Container className="history_area p-0 m-0" onScroll={(e) => onScroll(e)}>
      <History ChatHistory={ChatHistory} SetChatHistory={SetChatHistory} SetSelectedChat={SetSelectedChat} ScrollPosition={scroll} SideBarActive={SideBarActive} SetSideBarActive={SetSideBarActive} />
    </Container>
    <div className="bar"></div>
    <Container className="help_area p-0 m-0">
      <div className="helpsupport">
        <a href="#">
          <span className="big">Help &amp; Feedback</span>
          <span className="small"></span>
        </a>
      </div>
    </Container>
  </div>
};

export default SideBar;

/// This is the default layout page for the application. Sub directories may have separate layout files
import { useEffect, useState } from 'react';
import { Outlet, Link, useLocation } from "react-router-dom";
import Logo from '../components/logo';
import { useAuth } from "../helpers/AuthContext";
import UserService from "../services/UserServices";
import insideLogo from '../images/logoBlack.png';
import logotxt from '../images/insideLogoFull.png';
import { Col, Row, Container, Nav, Navbar, NavDropdown } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';
import axios from 'axios';
import { PersonFill } from 'react-bootstrap-icons';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import '../styles/shared.scss';
import globals from '../components/globals';
import SideBar from '../components/SideBar';
import Header from "../components/Header";
import CaseServices from '../services/CaseServices';
import { v4 as uuidv4 } from 'uuid';

const LayoutNew = ({loading}) => {
  const { login, logout, user, token } = useAuth();
  const [ pageClass, setPageClass] = useState("");
  let location = useLocation();

  globals.logout = () => logout();

  useEffect(() => {
    const regex = /[^\w\s]|\d/gi;
    let path = window.location.pathname;
    if (path = '/') {
      if (!token)
        setPageClass('login');
      else {
        if (!loading)
          UserService.isLoggedIn().then((response) => {
            if (response.data == true) {
              let p = window.location.pathname;
              if (p == '/')
                ;//setPageClass('landing')
              else {
                setPageClass(p.toLowerCase().replace(regex, ''));
                console.log(p.toLowerCase());
                if (p.toLowerCase() != '/new' && p.toLowerCase() != '/')
                  setSideBarActive(false);
              }
            }
            else
              logout();
          }
        );
      }
    }
    else
      setPageClass(path.toLowerCase().replace(regex, ''));
    console.log();
  }, [location.pathname]);

  const handleLogout = (event) => {
    event.preventDefault();
    UserService.logout().then(data => {
      logout();
    }).catch(err => {
      console.log(err);
    });
  }

  const [sideBarActive, setSideBarActive] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [chatList, setChatList] = useState([]);
  const [selectedChat, setSelectedChat] = useState({active: true, loaded: true, history : []});
  const msgSvc = null;//MessageService();

  useEffect(() => {
    if (!loaded) {
      //setPageClass('cases');
      setLoaded(true);
      //Call Data Loaded API HERE
      CaseServices.getChats().then((chats) => {
        //console.log(chats);

        for (var i = 0; i < chats.data.length; i++) {
            chats.data[i].MessageIdentifier = uuidv4();
            chats.data[i].active = false;
            chats.data[i].history = [];
            chats.data[i].waiting = false;
            chats.data[i].loaded = false;
            chats.data[i].message = "";
        }
        setChatList(chats.data);
      });
    }
  },[loaded]); 

  
  return (
    <div className={pageClass}>
      <section className="layout-height mt-0 pt-0">
        <Container className={sideBarActive == false ? "p-0 m-0 dashboard closed" : "p-0 m-0 dashboard"} >
          <Row className="p-0 m-0">
            <Col className="p-0 m-0 col-md-auto sidebar">
              <SideBar ChatHistory={chatList} SetChatHistory={setChatList} SetSelectedChat={setSelectedChat} SideBarActive={sideBarActive} SetSideBarActive={setSideBarActive} />
            </Col>
            <Col className="p-0 m-0 col-md-auto main">
              <Header />
              <Outlet context={[pageClass, setPageClass, selectedChat, setSelectedChat, msgSvc]} />
            </Col>
          </Row>
        </Container>            
      </section>
      <div className='poweredby'>Powered by Advent AI. &copy;2024</div>
    </div>
  )
};

export default LayoutNew;
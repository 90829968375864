import { Container } from "react-bootstrap";
import Image from '../components/Image';
import { useAuth } from "../helpers/AuthContext";
import "../styles/chatarea.scss";
import ChatBox from "./ChatBox";

const ChatArea = ({SelectedChat, SetSelectedChat, MessageService}) => {
  return <div className="chat-area">
    <ChatBox SelectedChat={SelectedChat} SetSelectedChat={SetSelectedChat} MessageService={MessageService} />
  </div>
};

export default ChatArea;

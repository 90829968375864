import globals from "../components/globals";

class CaseServices {
  getCases() {
    return globals.api.get('Document/');
  }

  getCase(id) {
    return globals.api.get('Document/' + id);
  }

  getChats() {
    return globals.api.get('Chat/GetChats');
  }

  getChatHistory(chatId) {
    return globals.api.get('Chat/GetChatHistory/'+chatId);
  }

  summarizeChatSession(title, chatId, chatMessageIds) {
    return globals.api.post('Chat/CreateSessionSummary', {
      'title': title,
      'chatId': chatId,
      'responses': chatMessageIds
    })
  }

  summarizeTitle(title) {
    return globals.api.post('Chat/CreateSessionTitle', title)
  }

  saveTitle(chatId, title) {
    return globals.api.post('Chat/SetChatTitle/'+chatId, title);
  }

  removeSession(chatId) {
    return globals.api.post('Chat/RemoveSession', chatId)
  }

  removeMessage(chatId, chatMessageId) {
    return globals.api.post('Chat/RemoveMessage/'+chatId, chatMessageId)
  }

  updateMessage(chatId, chatMessage) {
    return globals.api.post('Chat/UpdateMessage/'+chatId, chatMessage)
  }

  formatChatGPTResponse(message) {
    message = this.bold(message);
    message = this.order(message);
    message = this.newline(message);
    message = this.reference(message);
    return message;
  }

  bold(text) {
    var bold = /\*\*(.*?)\*\*/gm;
    var html = text.replace(bold, '<strong>$1</strong>');            
    return html;
  }
  
  order(text) {
    var regex = /\n(^\d+)\.(.*)\n/gm;
    var matches = text.matchAll(regex);
    var entries = [];
    for (const match of matches) 
        entries.push(match);
    if (entries.length == 0)
        return text;
    const last = entries[entries.length - 1];
    return text.slice(0, entries[0].index) + '<ol>' +  text.slice(entries[0].index,last.index + last[0].length).replace(regex, `<li index='$1'>$2</li>`) + '</ol>' + text.slice(last.index + last[0].length, text.length);
  }

  newline(text) {
    var line = /(.*?)\n/gm;
    var html = text.replace(line, '$1<br/>');
    var html = html.replace("</ol><br/>", "</ol>");
    return html;
  }

  reference(text) {
    //console.log(text);
    var line = /【(\d+)\†source】/g;
    var pdfs = /-\ ([A-Za-z0-9_ \-\%\$\&\*]*\.pdf)/g;
    var pdfEntries = [];
    var html = '';
    for (const match of text.matchAll(pdfs)) {
        pdfEntries.push(match);        
    }
    var lineEntries = [];
    for (const match of text.matchAll(line)) {
        lineEntries.push(match);
    }

    var start = 0;
    for (const match of pdfEntries) {
      html += text.substr(0, match.index);
      //console.log(match);
      html += `<a class="docref" href="#docs/${match[1]}">${text.substr(match.index, match[0].length)}</a>`;
      console.log(html);
      start = match.index + match[0].length;
    }
    html += text.substr(start, text.length - start);

    return html;
  }
}


export default new CaseServices();
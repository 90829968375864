import React,  {useState, useEffect, useCallback, useRef, createRef}  from "react";
import '../styles/fileupload.scss';
import moment from 'moment';
import { File, FileBinary, FileExcel, FileImage, FilePdf, FileText, FileWord, Folder, Folder2, FolderCheck, FolderFill, FolderX } from "react-bootstrap-icons";
import StorageServices from "../services/StorageServices";

const FileUpload = () => {
  const [loading, setLoading] = useState(false);
  const [entries, setEntries] = useState([]);

  const onDrop = (e, idx) => {
    e.preventDefault();
    console.log(e.dataTransfer);
    console.log(entries);
    //newlist = entries.map((x) =>  { return {...x, classes:x.classes.filter(c => c != "selected") }; });
    //console.log(entries);
    //setEntries([...entries]);      
    //var data = e.dataTransfer.getData("text");
    //ev.target.appendChild(document.getElementById(data));
  }

  const allowDrop = (e, item, idx) => {
    e.preventDefault();
    /*var list = entries.map((x) =>  { return {...x, classes:x.classes.filter(c => c != "selected") }; });
    //console.log(list, entries, idx, e);
    if (!list[idx].classes.includes("selected"))
      list[idx].classes.push("selected");
    console.log(list[idx]);
    // if (!entries[idx].selected) {
    //   console.log(e, item, idx);
    //   //item.Selected = true;
    //   entries.map(x => x.selected = false);
    //   entries[idx].selected = true;
    // }
    setEntries([...list]);      */
  }

  useEffect(() => {
    if (!loading) {
      StorageServices.getFiles().then(response => {
        setLoading(true);
        if (response.data) {
          let files = response.data.map(x => {
            let ext = StorageServices.getExtension(StorageServices.getFileName(x.name)).toLowerCase();
            return {
              "isFolder" : x.size == 0,
              "classes" : ["item", x.size == 0 ? "folder" : "file"],
              "name" : StorageServices.getFileName(x.name),
              "createdDate" : x.created,
              "isTxt": ext == "txt",
              "isPDF": ext == "pdf",
              "isExcel": ext == "xls" || ext == "xlsx",
              "isImg": ext == "png" || ext == "jpg" || ext == "bmp" || ext == "gif",
              "isDoc": ext == "doc" || ext == "docx",
            }
          });
          setEntries(files);
        }
      });
    }
    console.log("loading");
  }, [loading]);

  const onDragEnter = (e, item, idx) => {
    e.preventDefault();
    if (!entries[idx].classes.includes("selected"))
      entries[idx].classes.push("selected");
    setEntries([...entries]);
    console.log(entries);
  }


  const onDragLeave = (e, item, idx) => {
    e.preventDefault();
    var list = entries.map((x) =>  { return {...x, classes:x.classes.filter(c => c != "selected") }; });
    setEntries([...list]);      
    console.log(list);
  }

  return (<div className={loading ? "uploader loading" : "uploader"}>
    {entries.map((x, i) => (
        <div key={"item_"+i} className={x.classes.join(" ")} draggable="true" onDrop={e => x.isFolder && onDrop(e, i)} onDragEnter={e => x.isFolder && onDragEnter(e, x, i)} onDragLeave={e => x.isFolder && onDragLeave(e, x, i)} onDragOver={e => x.isFolder && allowDrop(e, x, i)}>
          {x.isFolder ? <Folder /> : 
          x.isPDF ? <FilePdf /> :
          x.isExcel ? <FileExcel /> :
          x.isImg ? <FileImage /> :
          x.isDoc ? <FileWord /> :
          x.isTxt ? <FileText /> :
          <FileBinary />}
          <div className="name no-drag">{x.name}</div>
          <div className="details no-drag">{moment(x.createdDate).format("L, LTS")}</div>
        </div>  
      ))
    }
  </div>);
};

export default FileUpload;
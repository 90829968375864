import { useState } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import '../styles/noauthlayout.scss';
import Spinner from 'react-bootstrap/Spinner';
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';
import { Outlet } from 'react-router-dom';
import largeLogo from '../images/loginLogo.png'
import Image from '../components/Image';
import { useAuth } from "../helpers/AuthContext";
import globals from '../components/globals';
//#548235


const NoAuthLayout = ({loading}) => {
  const [ pageClass, setPageClass] = useState("");
  const leftLogo = process.env.REACT_APP_LEFT_LOGO;
  const rightLogo = process.env.REACT_APP_RIGHT_LOGO;
  const leftImage = Image({ fileName:leftLogo, alt: 'GCT Logo', className: 'left-logo' })
  const rightImage = Image({ fileName:rightLogo, alt: 'EC Logo', className: 'right-logo' })
  const { logout } = useAuth();

  globals.logout = () => logout();

  return (
<div className={pageClass}>
  <section className="layout-height">
    <div className='layout-form'>
      <Container fluid className='layout-height'>
        <Row className='h-100 mt-0 px-0 p-0'>
          <Col className='col-12 mt-0 px-0 p-0'>
            <div className="flex-container">
              <div className="row"> 
                <div className="flex-item">
                  <div className='logo' style={{width:800}}>
                    <div className="left-logo-area">{leftImage}</div>
                    <div className="right-logo-area">{rightImage}</div>
                  </div>
                </div>
                <div className="flex-item">
                  <Outlet context={[pageClass, setPageClass]} />
                </div>
              </div>
            </div>
            <div className='poweredby'>powered by: Advent AI</div>
          </Col>
        </Row>
      </Container>      
    </div>
  </section>
</div>
  );
};

export default NoAuthLayout;
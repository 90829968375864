import React,  {useState, useEffect, useRef}  from "react";
import { Row, Col, Container, Button, Form, InputGroup, Card } from 'react-bootstrap';
import '../styles/documenteditor.scss';
import { Link, useLocation, useParams, useOutletContext } from "react-router-dom";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { convertFromHTML, ContentState } from 'draft-js';
import { convertToHTML } from 'draft-convert';
import 'draft-js/dist/Draft.css';

const ResearchForm = ({data}) => {
  const [loaded, setLoaded] = useState(false);
  const [editorState, setEditorState] = useState(EditorState.createEmpty())

  useEffect(() => {
    if (data) {
      setLoaded(true);
      //Call Data Loaded API HERE
      if (data != null) {
        // Perform API logic here
        //CaseServices.getCase(id).then(response => {
        //  console.log(response);
          var contentBlocks = convertFromHTML(data);//response.data.edits[0].html)
          var contentState = ContentState.createFromBlockArray(contentBlocks);
          var ed = EditorState.createWithContent( contentState );
        //  location.state = response.data;
          setEditorState(ed);
        //});
      }
    }
  },[data]); 

  const getHtmlContent = () => {
    const contentState = editorState.getCurrentContent();
    const rawContentState = convertToRaw(contentState);
    const htmlContent = convertToHTML(editorState.getCurrentContent());
    console.log(htmlContent);
    return htmlContent;
  };

  const onEditorStateChange = (editorState) => {
    setEditorState(editorState)
  };

  const generateDoc=()=>{
    CURAIServices.saveAsDoc(getHtmlContent()).then(response => {
      console.log(response);
      // Get the content disposition from the response headers
      const contentDisposition = response.headers['content-disposition'];
      
      // Extract the filename from the content disposition
      const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
      const fileName = fileNameMatch ? fileNameMatch[1] : 'download';

      // Create a Blob from the response data
      const blob = new Blob([response.data], { type: response.headers['content-type'] });

      // Create a download link
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = fileName;

      // Append the link to the document and trigger the download
      document.body.appendChild(link);
      link.click();

      // Remove the link from the document
      document.body.removeChild(link);      
    }).catch(err => {
      console.log(err);
    });
  }

  return (<>
    <Container className="mt-0 pt-0 pd-0">
      <Row>
        <Col md={1} className="mt-0 pt-0 pd-0"></Col>
        <Col md={10} className="mt-0 pt-0 pd-0">
          <Editor
            editorState={editorState}
            onEditorStateChange={onEditorStateChange}
          />
        </Col>
        <Col md={1} className="mt-0 pt-0 pd-0"></Col>
      </Row>
      {/* <Row>
        <Col md={1}></Col>
        <Col md={10}>
          <Button variant="primary mt-5" onClick={generateDoc}>Save Draft Order On Directions</Button>
        </Col>
      </Row> */}
    </Container>
  </>);
}

export default ResearchForm;
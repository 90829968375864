import globals from "../components/globals";

class UserServices {
  login(email, password) {
    return globals.api.post('login', {
      email: email,
      password: password
    })/*.then(function (res) {
      console.log(res);
      axios.defaults.headers.common['Authorization'] = res.token;    
    }).catch(function (err) {
      console.log(err);
    })*/;
  }

  logout() {
    return globals.api.post('logout')/*.then(function (res) {
      console.log(res);
    }).catch(function (err) {
      console.log(err);
    })*/;
  }

  register(firstName, lastName, email, password, account) {
    return globals.api.post('User/register', {
      firstName: firstName,
      lastName: lastName,
      email: email,
      password: password,
      account: account
    });
  }

  verify(registrationToken) {
    return globals.api.post('verify', { token : registrationToken });
  }

  isLoggedIn() {
    return globals.api.get('User/LoggedIn');
  }
  
  getUsers() {
    return globals.api.get('User/');
  }

  getUser(id) {
    return globals.api.get('User/' + id);
  }

  addUser(User) {
    return globals.api.post('User/', {
      firstName: User.firstName,
      lastName: User.lastName,
      email: User.email,
      password: User.password,
    });
  }

  updateUser(id, User) {
    return globals.api.put('User/' + id, {
      firstName: User.firstName,
      lastName: User.lastName,
      email: User.email,
      phone: User.phone,
      password: User.password,
      locked: User.locked,
    });
  }

  deleteUser(id) {
    return globals.api.delete('User/' + id);
  }
}


export default new UserServices();
import "../styles/chatboxinput.scss";
import { useState } from "react";
import { useMessageContext } from "../helpers/MessageContext";
import { useAuth } from "../helpers/AuthContext";
import { Container, Form, InputGroup } from "react-bootstrap";

const ChatBoxInput = ({SelectedChat, MessageService}) => {
  const { user } = useAuth();
  const userLetters = user.firstName[0] + "" + user.lastName[0];
  const [message, setMessage] = useState();
  const {SendChatMessage, connectionStatus} = useMessageContext();

  const sendMessage = (e, message) => {
    e.preventDefault();
    SendChatMessage(message, SelectedChat);
    //console.log(`Question Response = ${res}`);
  }

  return <>
    <Form onSubmit={(e) => sendMessage(e, message)}>
      <div className="chatbox-input-area">
        <div className="chatbox-input">
            <input disabled={SelectedChat.waiting} placeholder="Type your question here..." value={message} onChange={(e) => setMessage(e.target.value)}/>
            <div className="circle">{userLetters}</div>
            <button className="send" disabled={SelectedChat.waiting}>Send</button>
            <button className="ai-enhance" disabled={SelectedChat.waiting}></button>
        </div>
      </div>
    </Form>
    {/* <div className="input-area">
        <InputGroup>
          <InputGroup.Text className="export" disabled={SelectedChat.waiting}></InputGroup.Text>
          <Form.Control type="input" className="question" aria-label="Type your question here" placeholder="Type your question here..." disabled={SelectedChat.waiting} onSubmit={sendMessage} value={message} onChange={(e) => setMessage(e.target.value)} />
          <InputGroup.Text className="send" disabled={SelectedChat.waiting}></InputGroup.Text>
        </InputGroup>
      </div> */}
  </>
};

export default ChatBoxInput;

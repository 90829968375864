import { Container } from "react-bootstrap";
import Image from '../components/Image';
import { useAuth } from "../helpers/AuthContext";
import "../styles/header.scss";
import { Link } from "react-router-dom";
import "bootstrap-icons/font/bootstrap-icons.css";

const Header = () => {
  const { user } = useAuth();
  const leftLogo = process.env.REACT_APP_LEFT_LOGO;
  const rightLogo = process.env.REACT_APP_RIGHT_LOGO;
  const userLetters = user.firstName[0] + "" + user.lastName[0];

  return <div className="header">
    <div className="left-logo"><Link to="/new"><Image fileName={leftLogo} alt='GCT Logo' className='left-logo' /></Link></div>
    <div className="border-gap"></div>
    <div className="right-logo"><Link to="/new"><Image fileName={rightLogo} alt='EC Logo' className='right-logo' /></Link></div>
    <div className="user-info">
      <div className="display">
        <div className="name">{user.firstName} {user.lastName}</div>
        <div className="button">
          <div className="circle">{userLetters}</div>          
        </div>
        <div className="menu">
          <div className="item settings"><Link to="/Settings">Application Settings</Link></div>
          <div className="item users"><Link to="/Users">Users</Link></div>
          <div className="item roles"><Link to="/Roles">Roles</Link></div>
          <div className="item logout"><Link to="/Logout">Logout</Link></div>
        </div>
      </div>
    </div>
  </div>
};

export default Header;

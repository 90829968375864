import globals from "../components/globals";

class StorageServices {
  getFiles() {
    return globals.api.get('Storage/');
  }

  getFile(fileName) {
    return globals.api.get('Storage/Info' + fileName);
  }

  deleteFile() {
    return globals.api.delete('Storage/' + fileName);
  }

  uploadFile(formData) {
    var imagefile = document.querySelector('#file');
    formData.append("image", imagefile.files[0]);
    globals.api.post('upload_file', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
    })

    return globals.api.get('Storage/');
  }

  getExtension(fileName) {
    return fileName.slice((Math.max(0, fileName.lastIndexOf(".")) || Infinity) + 1);
  }

  getFileName(fullFilePath) {
    return fullFilePath.split('\\').pop().split('/').pop();
  }
}


export default new StorageServices();
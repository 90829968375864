import React,  {useState, useEffect, useRef}  from "react";
import { Row, Col, Container, Button, Form, InputGroup, Card, Modal } from 'react-bootstrap';
import { Search, X, XCircle, ChatLeftText, BoxArrowUpRight, PencilSquare, Pencil, ArrowLeftRight, Check } from 'react-bootstrap-icons';
import Moment from 'react-moment';
import moment from 'moment';
import '../styles/export.scss';
import html2pdf from 'html2pdf.js';
import jsPDF from 'jspdf';
import CaseServices from "../services/CaseServices";

const ConfirmDeleteSession = ({chat, show, setShow, setRefresh}) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (!loaded) {
      setLoaded(true);
      //Call Data Loaded API HERE
    }
  },[loaded]); 

  const handleCloseDelete = () => setShow(false);

  const removeSession = _ => {
    CaseServices.removeSession(chat.chatId).then((response) => {
      console.log(response);
      setShow(false);
      setRefresh(true);
    });
  }


  return (<>
    <Modal className="export-chat" show={show} onHide={handleCloseDelete}>
      <Modal.Header closeButton>
        <Modal.Title>Delete Session</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Remove Session <strong>"{chat?.title}"</strong>?
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={removeSession}>Remove Session</Button>
        <Button variant="secondary" onClick={handleCloseDelete}>Cancel</Button>
      </Modal.Footer>
    </Modal>
  </>);
}

export default ConfirmDeleteSession;
import { Container } from "react-bootstrap";
import "../styles/history.scss";
import data from "./data.json";
import moment from "moment";
import CaseServices from "../services/CaseServices";
import { createRef, useEffect, useLayoutEffect, useMemo, useState } from "react";
import { v4 as uuidv4 } from 'uuid';

const History = ({ChatHistory, SetChatHistory, SetSelectedChat, ScrollPosition, SideBarActive, SetSideBarActive}) => {
  const [entries, setEntries] = useState([]);
  useMemo(() => {
    //console.log(ChatHistory);
    // Expensive calculation or function
    let list = [
      { "day": "Today", "entries" : ChatHistory.filter(c => moment(c.createdDate) >= moment().startOf('day') ).map(x => { return {...x, 'REF': createRef()} }) },
      { "day": "Yesterday", "entries" : ChatHistory.filter(c => moment(c.createdDate) >= moment().subtract(1, 'days').startOf('day') && moment(c.createdDate) < moment().startOf('day') ).map(x => { return {...x, 'REF': createRef()} }) },
      { "day": "This Week", "entries" : ChatHistory.filter(c => moment(c.createdDate) >= moment().subtract(8, 'days').startOf('day') && moment(c.createdDate) < moment().subtract(1, 'days').startOf('day') ).map(x => { return {...x, 'REF': createRef()} }) },
      { "day": "Older", "entries" : ChatHistory.filter(c => moment(c.createdDate) < moment().subtract(8, 'days').startOf('day') ).map(x => { return {...x, 'REF': createRef()} }) },
    ];
    setEntries(list);
  }, [ChatHistory]);

  const activateChat = (e, chat, index) => {
    console.log(e, chat, index);
    if (!chat.loaded || !chat.history) {
      CaseServices.getChatHistory(chat.chatId).then((response) => {
        if (response && response.data && response.data.chatMessages) {
            for (var i = 0; i < response.data.chatMessages.length; i++) {
                response.data.chatMessages[i].chatMessage = CaseServices.formatChatGPTResponse(response.data.chatMessages[i].chatMessage);
                response.data.chatMessages[i].REF = createRef();
            }

          chat.loaded = true;
          var s = {...chat, history : response.data.chatMessages};
          index = ChatHistory.findIndex(x=> x.chatId == s.chatId);
          ChatHistory[index] = s;
          SetChatHistory([...ChatHistory])
          SetSelectedChat(s);
        }
      });
    }
    else {
      console.log(chat.history.length > 0 ? "HISTORY = true" : "HISTORY = false");
      SetSelectedChat(chat);
    }
  }

  const [hover, setHover] = useState([]);
  //const memoizedValue = 
  const isEllipsisActive = (e, i) => {
    //console.log(e,e.target.parentNode.offsetWidth,e.target.scrollWidth);
    if (e && e.current && e.current.parentNode.parentNode.offsetWidth <= e.current.scrollWidth)
      return true;

    return false;
  }

  const [animationStop, setAnimationStop] = useState(false);

  useLayoutEffect(() => {
    if (SideBarActive) {
      if (animationStop)
        setAnimationStop(false);
      console.log("HERE")
      let updatedChatHistory = entries.map(x => {
        return {...x, entries: x.entries.map(y => {
          //console.log(y.REF.current.getBoundingClientRect(), ScrollPosition);
          return {...y, 'isOverflow': isEllipsisActive(y.REF,y), 'ofs': y.REF.current.getBoundingClientRect().top + ScrollPosition}
        })}
      })
      setEntries(updatedChatHistory);
    }
  },[ChatHistory, SideBarActive, animationStop])

  const activateSideBar = function() {
    SetSideBarActive(true);
    let timeout = 1000;
    setTimeout(() => {
      setAnimationStop(true);
    }, timeout);
  }

  return <div className="history">
    <div className="clock" onClick={activateSideBar}></div>
    {entries.map(x => (!x.entries.length ? null : 
    <div className="entry" key={x.day}>
      <div className="title">{x.day}</div>
      <ul>
      {x.entries.map((k, i) => (
        <li key={x.day + "_li_" + k.chatId}>
          <div className={k.isOverflow ? "has-ellispsis" : ""} style={{top:k.ofs && (k.ofs - ScrollPosition)}} ref={k.REF} onClick={(e) => activateChat(e, k, i)}>{k.title}</div>
        </li>
      ))}
      </ul>
    </div>)
   )}
  </div>
};

export default History;

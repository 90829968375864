import SideBar from "../components/SideBar";
import Header from "../components/Header";
import { Col, Container, Row } from "react-bootstrap";
import ChatArea from "../components/ChatArea";
import CaseServices from "../services/CaseServices";
import "../styles/dashboardnew.scss";
import { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';
import MessageService from "../helpers/MessageContext";

const DashboardNew = () => {
  const [,,selectedChat, setSelectedChat, msgSvc] = useOutletContext();
  return <ChatArea SelectedChat={selectedChat} SetSelectedChat={setSelectedChat} MessageService={msgSvc} />
};

export default DashboardNew;

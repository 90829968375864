import React,  {useState, useEffect, useRef}  from "react";
import { Row, Col, Container, Button, Form, InputGroup, Card, Modal } from 'react-bootstrap';
import { Search, X, XCircle, ChatLeftText, BoxArrowUpRight, PencilSquare, Pencil, ArrowLeftRight, Check } from 'react-bootstrap-icons';
import Moment from 'react-moment';
import moment from 'moment';
import '../styles/export.scss';
import html2pdf from 'html2pdf.js';
import jsPDF from 'jspdf';
import { useAuth } from "../helpers/AuthContext";

const ExportChat = ({chat, show, setShow}) => {
  const [loaded, setLoaded] = useState(false);
  const exportRef = useRef(null);
  const { user } = useAuth();
  const botLetters = process.env.REACT_APP_CHAT_BOT_LETTERS ?? "A";

  useEffect(() => {
    if (!loaded) {
      setLoaded(true);
      //Call Data Loaded API HERE
    }
  },[loaded]); 

  const handleCloseExport = () => setShow(false);
  const saveAsPDF = () => {
    const options = {
      filename: 'my-document.pdf',
      margin: 1,
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: {
        unit: 'in',
        format: 'letter',
        orientation: 'portrait',
      },
    };

    html2pdf().set(options).from(exportRef.current).save();

    setShow(false);
  }

  const saveAsDoc = () => {
    setShow(false);
  }

  const saveAsText = () => {
    setShow(false);
  }

  return (<>
    <Modal className="export-chat" show={show} onHide={handleCloseExport}>
      <Modal.Header closeButton>
        <Modal.Title>Export Research Session</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <div className="scrollarea" ref={exportRef}>
      {chat?.history.map((msg, idx) => (
        <div key={"history_" + msg.chatMessageId}>
          {!msg.isResponse ? <div className="question">{user.firstName[0]+user.lastName[0]}</div> : <div className="response">{botLetters}</div>}
          <div className="message" dangerouslySetInnerHTML={{__html: msg.chatMessage}}></div>
        </div>
        ))}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div>
          <span className="title">{chat?.title}</span>
          <span className="created">{moment(chat?.createdDate).format('LLL')}</span>
        </div>
        <Button variant="primary" onClick={saveAsPDF}>Save PDF</Button>
        <Button variant="secondary" onClick={handleCloseExport}>Close</Button>
      </Modal.Footer>
    </Modal>
  </>);
}

export default ExportChat;
import axios from 'axios';
import globals from './components/globals';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ProtectedRoute } from "./helpers/ProtectedRoute";

import logo from './logo.svg';
import './App.scss';
import Layout from "./pages/Layout";
import Login from "./pages/Login";
import Users from "./pages/Users";
import Roles from "./pages/Roles";
import Four04 from "./pages/Four04";
import Logout from "./pages/Logout";
import Chat  from "./pages/Chat";
import DashboardNew from "./pages/DashboardNew";
import Registration from "./pages/Registration";
import { AuthProvider } from "./helpers/AuthContext";
import NoAuthLayout from "./pages/NoAuthLayout";

import { useEffect, useState, useRef } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';
import LayoutNew from './pages/LayoutNew';
import FileUpload from './pages/FileUpload';

function App() {
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const pageTitle = process.env.REACT_APP_PAGE_TITLE ?? "AIDA";

  const token = window.localStorage.getItem("token");
  globals.api = axios.create({
    baseURL: process.env.REACT_APP_APP_API_ENDPOINT,
    headers: { 
      post: {'Content-Type' : 'application/json'},
      Authorization: "Bearer " + JSON.parse(token)
    }
  });
   
  //axios.defaults.baseURL = process.env.REACT_APP_APP_API_ENDPOINT;
  //axios.defaults.headers.post['Content-Type'] = 'application/json';
  globals.api.interceptors.request.use(function (config, b) {
    const token = window.localStorage.getItem("token");
    console.log(config, b);
    if (!token) {
      return config
    }
    config.headers.Authorization = "Bearer " + JSON.parse(token);
    if (config.url && config.url.toLowerCase() != "user/loggedin")
      setLoading(true);
  
    return config;
  }, function (error) {
    setLoading(false);
    setShow(true)
    setErrorMessage("Error sending request: " + error.message);
    return Promise.resolve(); //Promise.reject(error);
  });
  
  globals.api.interceptors.response.use((response) => {
    setLoading(false);
    return response;
  }, (error) => {
    setLoading(false);
    if (error.response && error.response.status) {
      if (error.response.status != 401)  {
        setShow(true);
        setErrorMessage("Error " + error.response.status + " from server");
      } else {
        globals.logout();
      }
    } else {
      if (error.response && error.response.status && error.response.status == 401) {
        globals.logout();
        return  new Promise(() => { });
      }
      setShow(true);
      setErrorMessage("Error: " + error.code + " Reason: " + error.message);
    }
    return new Promise(() => { }); //return Promise.resolve() //return Promise.reject(error);
  });
  
  // Add a request interceptor
  console.log(process.env);
  useEffect(() => {
    document.title = pageTitle;
  }, []);
  
  function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * Close toast if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setShow(false);
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  return (
    <div>
    {loading == true ? 
    <div className='loading-indicator'>
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    </div>
    : <></>
    }
    <ToastContainer className="position-absolute" position='top-center'>
      <Toast onClose={() => setShow(false)} show={show} bg='danger' ref={wrapperRef} style={{marginRight:'50px',marginTop:'25px'}}>
        <Toast.Header>
          <strong className="me-auto">Error Processing Request</strong>
        </Toast.Header>
        <Toast.Body>
          <strong>An error has occured while processing a request</strong>
          <small className="text-muted"></small>
          <br />
          {errorMessage}
        </Toast.Body>
      </Toast>
    </ToastContainer>      
    <BrowserRouter>
      <AuthProvider>
        <Routes>
          <Route element={<NoAuthLayout state={loading} />}>
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Registration />} />
          </Route>
          <Route element={<ProtectedRoute />}>
            <Route element={<Layout state={loading} />}>
              <Route path="/" element={<Chat />} />
            </Route>
            <Route element={<LayoutNew state={loading} />}>
              <Route path="/New" element={<DashboardNew />} />
              <Route path="/Users" element={<Users />} />
              <Route path="/Roles" element={<Roles />} />
              <Route path="/Upload" element={<FileUpload />} />
              <Route path="/Logout" element={<Logout />} />
            </Route>
          </Route>
          <Route path="*" element={<Four04 />} />
        </Routes>
      </AuthProvider>      
    </BrowserRouter>
    </div>
  );
}

export default App; 
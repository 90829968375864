import '../styles/indicator.scss';
const Indicator = ({ className }) => {
    return (<div className={`${className}`}>
        <div className="dot-indicator">
          <div>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
        {/* <div class="typing-indicator">
          <span></span>
          <span></span>
          <span></span>
        </div> */}
      </div>
    )
}

export default Indicator
import { Container, Form, InputGroup } from "react-bootstrap";
import Image from '../components/Image';
import { useAuth } from "../helpers/AuthContext";
import "../styles/chatbox.scss";
import { createRef, useEffect, useRef, useState } from "react";
import CaseServices from "../services/CaseServices";
import { useMessageContext } from "../helpers/MessageContext";
import ChatBoxInput from "./ChatBoxInput";

const ChatBox = ({SelectedChat, SetSelectedChat, MessageService}) => {
  const { user } = useAuth();
  const userName = user.firstName + " " + user.lastName;
  const userLetters = user.firstName[0] + "" + user.lastName[0];
  const botName = "AIDA";
  const botLetters = "AA";
  const [opacity, setOpacity] = useState(1);
  const [title, setTitle] = useState('');
  const [message, setMessage] = useState();
  const {SendChatMessage, connectionStatus, ReceivedMessage} = useMessageContext();

  useEffect(() => {
    setTitle(SelectedChat?.title);
    scrollToBottom();
  }, [SelectedChat])

  const useMouseMove = (onMouseMove) => {
    useEffect(() => {
        document.addEventListener("mousemove", onMouseMove)

        return () => {
            document.removeEventListener("mousemove", onMouseMove)
        }
    }, [onMouseMove])
  }  

  const messagesEndRef = useRef(null);
  const scrollToBottom = () => {
    setTimeout(() => {
      messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    }, 100)
  }
    
  useMouseMove(e => {
    e.clientY ? setOpacity(120 / e.clientY) : 0;
  });

  const sendMessage = (e, message) => {
    e.preventDefault();
    SendChatMessage(message, SelectedChat);
    //console.log(`Question Response = ${res}`);
  }

  useEffect(() => {
    if (ReceivedMessage == null || ReceivedMessage == undefined)
      return;
    console.log(ReceivedMessage);
  }, [ReceivedMessage]);

  return <div className="chat-box">
    <div className="title" style={{opacity:opacity}}><span>{title}</span></div>
    <div className="message-area">
      <div className="messages">
        {!SelectedChat || SelectedChat.history.length == 0 ? 
        <div className="initial">
          <span>Hello, {user.firstName}!</span>
          <span>Let&rsquo;s get started with {botName}</span>
        </div> : SelectedChat.history.map(x => (
        <div className={"message " + (x.isResponse ? "response" : "question")}>
          <div className="user">
          {x.isResponse ? <>
            <div className="button"><div className="circle">{botLetters}</div></div>
            <div className="name">{botName}</div></> : <>
            <div className="name">{x.isResponse ? botLetters : userName}</div>
            <div className="button"><div className="circle">{x.isResponse ? botName : userLetters}</div></div></>
          }
          </div>
          <div className="bubble" dangerouslySetInnerHTML={{__html: x.chatMessage}}></div>
        </div>
        ))}
        <div ref={messagesEndRef}>&nbsp;</div>
      </div>
    </div>
    <div>
      <ChatBoxInput SelectedChat={SelectedChat} MessageService={MessageService} />
    </div>
  </div>
};

export default ChatBox;

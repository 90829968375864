import useWebSocket from "react-use-websocket";
import { useAuth } from "./AuthContext";
import { useEffect, useState } from "react";
import { DEFAULT_OPTIONS, ReadyState } from "react-use-websocket/dist/lib/constants";
import { Question } from "react-bootstrap-icons";

const MessageContext = () => {
  const { user, token } = useAuth();
  const url = new URL(process.env.REACT_APP_APP_API_ENDPOINT);
  const WS_URL = `wss://${url.host}/ws`;
  const [socketUrl, setSocketUrl] = useState(WS_URL);
  const [ReceivedMessage, setReceivedMessage] = useState();

  const { sendMessage, lastMessage, readyState } = useWebSocket(socketUrl,
    {
      //...DEFAULT_OPTIONS,
      protocols: [`Authorization`, token],
      shouldReconnect: (closeEvent) => true,
      reconnectAttempts: 10,
      //attemptNumber will be 0 the first time it attempts to reconnect, so this equation results in a reconnect pattern of 1 second, 2 seconds, 4 seconds, 8 seconds, and then caps at 10 seconds until the maximum number of attempts is reached
      reconnectInterval: (attemptNumber) =>
      Math.min(Math.pow(2, attemptNumber) * 1000, 10000),
    });

  const connectionStatus = {
    [ReadyState.CONNECTING]: 'Connecting',
    [ReadyState.OPEN]: 'Connected',
    [ReadyState.CLOSING]: 'Disconnecting',
    [ReadyState.CLOSED]: 'Disconnected',
    [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
  }[readyState];


  const SendChatMessage = (Question, Chat) => {
    console.log(Question, Chat)
    //return "Response " + Question + " " + ` Chat ${Chat}`;

    sendMessage(JSON.stringify({
      MessageType: "Question",
      MessageIdentifier : Chat.MessageIdentifier,
      ChatId : Chat.chatId,
      UserId : user.id,
      Title: Chat.title,
      ChatMessage : Question
    }));            
    //session.message = "";
  }

  useEffect(() => {
    if (lastMessage !== null && lastMessage !== undefined) {
      var msg = JSON.parse(lastMessage.data);
      console.log(lastMessage);
      setReceivedMessage(lastMessage);
      // msg.chatMessage = bold(msg.chatMessage);
      // msg.chatMessage = order(msg.chatMessage);
      // msg.chatMessage = newline(msg.chatMessage);
      // var chats = [...chatList];
      // for (var i = 0; i < chatList.length; i++) {
      //   if (chats[i].MessageIdentifier ==  msg.messageIdentifier) {
      //       chats[i].chatId = msg.chatId;
      //       var idx = chats[i].history.findIndex(c => c.chatMessageId == msg.chatMessageId);
      //       if (idx == -1) {
      //         var old = {...chats[i].history[chats[i].history.length - 2]};
      //         //chats[i].history[chats[i].history.length - 2] = {...chats[i].history[chats[i].history.length - 2], chatMessageId : msg.requestorId, chatId : msg.chatId};
      //         old.chatMessageId = msg.requestorId;
      //         old.chatId = msg.chatId;
      //         chats[i].history[chats[i].history.length - 1] = msg;
      //         chats[i].history[chats[i].history.length - 2] = old;
      //       } else {
      //         chats[i].history[idx].chatMessage = msg.chatMessage;
      //       }
      //       //setElRefs((elRefs) => Array(chats[i].history.length).fill().map((_, p) => elRefs[p] || createRef()),);
      //       chats[i].history = chats[i].history.map((c) => ({...c, REF : c.REF || createRef()}));
      //       chats[i].waiting = false;
      //       chats[i].history = [...chats[i].history];
      //   }
      // }
      // // add or remove refs
      // setChatList(chats);

      // scrollToBottom();
    }
  }, [lastMessage]);

  var ReceiveMessage;

  const GetChatHistory = (ChatId) => {
    sendMessage(JSON.stringify({
      MessageType: "GetHistory",
      ChatId : ChatId,
      UserId : user.id
    }));
  }

  const GenerateChatTitle = (Question, Chat) => {
    sendMessage(JSON.stringify({
      MessageType: "GenerateTitle",
      MessageIdentifier : Chat.MessageIdentifier,
      ChatId : Chat.chatId,
      UserId : user.id,
      ChatMessage : Question
    }));
  }

  const GenerateChatSummary = (ChatId) => {
    sendMessage(JSON.stringify({
      MessageType: "GenerateSummary",
      ChatId : ChatId,
      UserId : user.id
    }));
  }

  return {
    SendChatMessage: SendChatMessage,
    ReceivedMessage: ReceivedMessage,
    GetChatHistory: GetChatHistory,
    GenerateChatTitle: GenerateChatTitle,
    GenerateChatSummary: GenerateChatSummary,
    connectionStatus: connectionStatus
  };
}

export const useMessageContext = () => {
  return MessageContext();
}
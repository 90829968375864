import globals from "../components/globals";

class RoleServices {
  getRoles() {
    return globals.api.get('Role/');
  }

  getRole(id) {
    return globals.api.get('Role/' + id);
  }

  addRole(Role) {
    return globals.api.post('Role/', {
      name: Role.firstName,
      description: Role.lastName,
      permissions: Role.permissions,
    });
  }

  updateRole(id, Role) {
    return globals.api.put('Role/' + id, {
      name: Role.firstName,
      description: Role.lastName,
      permissions: Role.permissions,
    });
  }

  deleteRole(id) {
    return globals.api.delete('Role/' + id);
  }
}


export default new RoleServices();